export const data = [
  {
    id: 'prog01',
    title: 'Project and Research Lead',
    description: 'What, Why, How and When',
    location: 'Nigeria',
    date: null,
    year: 2023,
  },
  {
    id: 'prog02',
    title: 'Oral Health',
    description:
      'Oral health talk and Orthodontics awareness campaign at various public and private schools and religious institutions in the geo-political regions of Nigeria.',
    location: 'Nigeria',
    date: null,
    year: 2023,
  },
  {
    id: 'prog03',
    title: 'Master Class',
    description: 'Seminars on contemporary issues in Orthodontics.',
    location: 'Nigeria',
    date: null,
    year: 2023,
  },
  {
    id: 'prog04',
    title: 'Orthodontics in COVID-19 Era',
    description: 'Orthodontic treatment in COVID-19 era.',
    location: 'Nigeria',
    date: null,
    year: 2023,
  },
  {
    id: 'prog05',
    title: 'Surveys',
    description: 'Orthodontic surveys',
    location: 'Nigeria',
    date: null,
    year: 2023,
  },
  {
    id: 'prog06',
    title: 'Orthodontic Treatment',
    description:
      'Free / subsidized orthodontic treatment for selected less privileged, elderly and qualified individuals in the Society.',
    location: 'Nigeria',
    date: null,
    year: 2023,
  },
];

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalLogin: false,
  userName: '',
  isSuccess: false,
};

export const pabracesSlice = createSlice({
  name: 'pabraces',
  initialState,

  reducers: {
    setGlobalLogin: (state, action) => {
      state.globalLogin = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGlobalLogin, setUserName, setIsSuccess } =
  pabracesSlice.actions;

export default pabracesSlice.reducer;

import React, { useState, useEffect } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBAlert,
} from 'mdbreact';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from '../assets/images/logo2.png';
// import logo_alt from '../assets/images/logo.png';

import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { auth, provider } from '../helpers/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalLogin, setUserName } from '../features/pabracesSlice';

const initialState = {
  name: '',
  email: '',
  password: '',
  key: '',
  joined: new Date(),
  submitError: false,
  loginError: false,
  errorMsg: 'wrong email or password!',
  modal1: false,
  loginForm: true,
  loggedIn: false,
  isOpen: false,
  home: false,
  // home2: false,
  about: false,
  programs: false,
  join: false,
  gallery: false,
  contact: false,
  programActive: '',
};

let response = null;
const localStore = localStorage.getItem('localData');
const Navbar = (props) => {
  const globalLogin = useSelector((state) => state.pabraces.globalLogin);
  const [state, setState] = useState({ ...initialState });

  const dispatch = useDispatch();

  // console.log('globalLogin is ', globalLogin);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user || localStorage.getItem('localData')) {
        return dispatch(setGlobalLogin(true));
      }
      dispatch(setGlobalLogin(false));
    });
  });

  // useEffect(() => {
  //   localStore
  //     ? dispatch(setGlobalLogin(true))
  //     : dispatch(setGlobalLogin(false));
  // }, [localStore]);
  // console.log('localStore is ', localStore);

  // using google signin
  const signInWithGoogle = () => {
    if (globalLogin) {
    } else {
      signInWithPopup(auth, provider);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const username = user.displayName;
          dispatch(setGlobalLogin(true));
          dispatch(setUserName(username));
          setState({ ...state, loggedIn: true, loginError: false });
          response = user;

          try {
            if (response) {
              toastSubmitPost();
              let info = {
                adminId: response.uid,
                token: response.accessToken,
              };
              localStorage.setItem('localData', JSON.stringify(info));
            } else if (!response) {
              setState({
                loggedIn: false,
                loginError: true,
                errorMsg: 'wrong email or password!',
                modal1: state.modal1,
              });
              alert('wrong email or password!');
              //
            } else {
              setState({
                loggedIn: false,
                loginError: true,
                modal1: state.modal1,
              });
            }
          } catch (error) {
            console.log('error is ', error);
            alert('something went wrong!');
          }
          return;
        }
      });
    }
  };

  const toggle = (nr) => () => {
    let modalNumber = 'modal' + nr;
    setState({
      [modalNumber]: !state[modalNumber],
    });
  };

  const toggleCollapse = () => {
    setState({ isOpen: !state.isOpen });
  };

  const handleStyle = (nr) => (e) => {
    e.preventDefault();
    toggleCollapse();
    if (nr === 1) {
      setState({
        home: true,
        about: false,
        programs: false,
        join: false,
        gallery: false,
        contact: false,
      });
      props.history.push('/');
    }
    if (nr === 2) {
      setState({
        home: false,
        home2: false,
        about: true,
        programs: false,
        join: false,
        gallery: false,
        contact: false,
      });
      props.history.push('/about');
    }
    if (nr === 3) {
      setState({
        home: false,
        home2: false,
        about: false,
        programs: true,
        join: false,
        gallery: false,
        contact: false,
      });
      props.history.push('/programs');
    }
    if (nr === 4) {
      setState({
        home: false,
        home2: false,
        about: false,
        programs: false,
        join: true,
        gallery: false,
        contact: false,
      });
      props.history.push('/join');
    }
    if (nr === 5) {
      setState({
        home: false,
        home2: false,
        about: false,
        programs: false,
        join: false,
        gallery: true,
        contact: false,
      });
      props.history.push('/gallery');
    }
    if (nr === 6) {
      setState({
        home: false,
        home2: false,
        about: false,
        programs: false,
        join: false,
        gallery: false,
        contact: true,
      });
      props.history.push('/contact');
    }
  };

  const changeHandler = (e) => {
    e.preventDefault();
    setState({
      [e.target.name]: e.target.value,
    });
  };

  const toastSubmitPost = () => {
    toast.success('Operation successfull!');
  };

  const signupSubmit = () => {
    axios
      .post('https://pabraces.herokuapp.com/admin/signup', state)
      .then((response) => {
        if (response.status === 200) {
          toastSubmitPost();
          setState({
            submitError: false,
            modal1: false,
          });
          loginSubmit();
        } else {
          setState({
            submitError: true,
            // modal1: false
          });
        }
      })
      .catch((error) => error);
    setState({
      submitError: true,
      // modal1: false
    });
  };

  const toastWrongUser = () => {
    toast.error('wrong username or password!');
  };

  const loginSubmit = async () => {};

  const logout = () => {
    signOut(auth);
    response = null;
    dispatch(setGlobalLogin(false));
    setState({ ...state, loggedIn: false });
    localStorage.clear();
    // window.location.reload();
  };

  const { name, email, password, key } = state;

  return (
    // <Router>
    <MDBNavbar
      color="rgba-stylish-light"
      fixed="top"
      dark
      expand="lg"
      scrolling
      transparent
    >
      <MDBNavbarBrand>
        <img
          src={logo}
          alt="Foundation's logo"
          className="w-50 img-fluid mr-3"
        />
      </MDBNavbarBrand>
      <MDBNavbarToggler onClick={toggleCollapse} />
      <MDBCollapse
        id="navbarCollapse3"
        isOpen={state.isOpen}
        navbar
        className="transparent-bg-strong"
      >
        <MDBNavbarNav left>
          {state.home === true ? (
            <MDBNavItem active className="mr-3">
              <MDBNavLink to="/" onClick={handleStyle(1)}>
                Home
              </MDBNavLink>
            </MDBNavItem>
          ) : (
            <MDBNavItem className="mr-3">
              <MDBNavLink to="/" onClick={handleStyle(1)}>
                Home
              </MDBNavLink>
            </MDBNavItem>
          )}

          {state.about === true ? (
            <MDBNavItem active className="mr-3">
              <MDBNavLink to="#!" onClick={handleStyle(2)}>
                About
              </MDBNavLink>
            </MDBNavItem>
          ) : (
            <MDBNavItem className="mr-3">
              <MDBNavLink to="#!" onClick={handleStyle(2)}>
                About
              </MDBNavLink>
            </MDBNavItem>
          )}

          {state.programs === true ? (
            <MDBNavItem active className="mr-3">
              <MDBNavLink to="#!" onClick={handleStyle(3)}>
                Programs
              </MDBNavLink>
            </MDBNavItem>
          ) : (
            <MDBNavItem className="mr-3">
              <MDBNavLink to="#!" onClick={handleStyle(3)}>
                Programs
              </MDBNavLink>
            </MDBNavItem>
          )}

          {state.join === true ? (
            <MDBNavItem active className="mr-3">
              <MDBNavLink to="/Join" onClick={handleStyle(4)}>
                Join
              </MDBNavLink>
            </MDBNavItem>
          ) : (
            <MDBNavItem className="mr-3">
              <MDBNavLink to="/Join" onClick={handleStyle(4)}>
                Join
              </MDBNavLink>
            </MDBNavItem>
          )}

          {state.gallery === true ? (
            <MDBNavItem active className="mr-3">
              <MDBNavLink to="#!" onClick={handleStyle(5)}>
                Gallery
              </MDBNavLink>
            </MDBNavItem>
          ) : (
            <MDBNavItem className="mr-3">
              <MDBNavLink to="#!" onClick={handleStyle(5)}>
                {' '}
                Gallery{' '}
              </MDBNavLink>
            </MDBNavItem>
          )}

          {state.contact === true ? (
            <MDBNavItem active className="mr-3">
              <MDBNavLink to="/Contact" onClick={handleStyle(6)}>
                Contact{' '}
              </MDBNavLink>
            </MDBNavItem>
          ) : (
            <MDBNavItem className="mr-3">
              <MDBNavLink to="#!" onClick={handleStyle(6)}>
                Contact{' '}
              </MDBNavLink>
            </MDBNavItem>
          )}
        </MDBNavbarNav>
        <MDBNavbarNav right>
          <MDBNavItem>
            <MDBNavLink
              className="waves-effect waves-light"
              to="#!"
              onClick={() =>
                window.open(
                  'https://web.facebook.com/Professor-Emmanuel-Olubusayo-Ajayi-Orthodontics-Advancement-Foundation-104807494848421',
                  '_blank'
                )
              }
            >
              <MDBIcon fab icon="facebook" />
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              className="waves-effect waves-light"
              to="#!"
              onClick={() =>
                window.open('https://twitter.com/BracesProf', '_blank')
              }
            >
              <MDBIcon fab icon="twitter" />
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              className="waves-effect waves-light"
              to="#!"
              onClick={() =>
                window.open(
                  'https://www.instagram.com/profajayibraces/',
                  '_blank'
                )
              }
            >
              <MDBIcon fab icon="instagram" />
            </MDBNavLink>
          </MDBNavItem>
          {globalLogin ? (
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon icon="user" /> Admin
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default">
                  <MDBDropdownItem href="#!">
                    <div
                      className="waves-effect waves-light"
                      to="#!"
                      onClick={logout}
                    >
                      <MDBIcon icon="sign-out-alt" className="px-2" />
                      Logout
                      {/* <MDBBtn size="sm" color="white" outline>
                         Logout </MDBBtn> */}
                    </div>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          ) : (
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon icon="user" />
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default">
                  <MDBDropdownItem href="#!">
                    <div
                      className="waves-effect waves-light"
                      to="#!"
                      onClick={signInWithGoogle}
                    >
                      <MDBIcon icon="sign-in-alt" className="px-2" />
                      login
                    </div>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          )}
        </MDBNavbarNav>

        {/* login modal */}
        <MDBModal isOpen={state.modal1} toggle={toggle(1)} centered>
          <MDBModalHeader
            toggle={toggle(1)}
            titleClass="d-inline title"
            className="text-center light-blue darken-3 white-text px-2"
          >
            <MDBIcon icon="user" classname="pr-2" />
            &nbsp; Admin
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol>
                <form>
                  <div className="text-center">
                    <MDBBtn
                      color="white"
                      onClick={() => setState({ loginForm: true })}
                    >
                      Login
                    </MDBBtn>
                    <MDBBtn
                      color="primary"
                      onClick={() => setState({ loginForm: false })}
                    >
                      Sign up
                    </MDBBtn>
                  </div>
                  {state.loginForm ? (
                    <div>
                      <div className="grey-text">
                        <MDBInput
                          name="email"
                          label="Type your email"
                          icon="envelope"
                          group
                          type="email"
                          validate
                          error="wrong"
                          success="right"
                          className="black-text pl-5"
                          onChange={changeHandler}
                          value={email}
                        />
                        <MDBInput
                          name="password"
                          label="Type your password"
                          icon="lock"
                          group
                          type="password"
                          validate
                          className="black-text pl-5"
                          onChange={changeHandler}
                          value={password}
                        />
                      </div>
                      <div className="text-center">
                        {state.loginError === true ? (
                          <MDBAlert color="danger">
                            <strong>Oops!</strong> {state.errorMsg}
                          </MDBAlert>
                        ) : null}
                        <MDBBtn
                          color="white"
                          onClick={() => {
                            setState({
                              submitError: false,
                              loggedIn: true,
                              modal1: false,
                            });
                            loginSubmit();
                          }}
                        >
                          <MDBIcon icon="paper-plane" claassName="pr-2" />
                          &nbsp; Login{' '}
                        </MDBBtn>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="grey-text">
                        <MDBInput
                          name="name"
                          label="Type your name"
                          icon="user"
                          group
                          type="text"
                          validate
                          error="wrong"
                          success="right"
                          className="black-text pl-5"
                          onChange={changeHandler}
                          value={name}
                        />
                        <MDBInput
                          name="email"
                          label="Type your email"
                          icon="envelope"
                          group
                          type="email"
                          validate
                          error="wrong"
                          success="right"
                          className="black-text pl-5"
                          onChange={changeHandler}
                          value={email}
                        />
                        <MDBInput
                          name="password"
                          label="Type your password"
                          icon="lock"
                          group
                          type="password"
                          validate
                          className="black-text pl-5"
                          onChange={changeHandler}
                          value={password}
                        />
                        <MDBInput
                          name="key"
                          label="Enter key"
                          icon="key"
                          group
                          type="text"
                          validate
                          error="wrong"
                          success="right"
                          className="black-text pl-5"
                          onChange={changeHandler}
                          value={key}
                        />
                      </div>
                      <div className="text-center">
                        {state.submitError === true ? (
                          <MDBAlert color="danger">
                            <strong>Oops!</strong> Something went wrong
                          </MDBAlert>
                        ) : null}
                        <MDBBtn color="primary" onClick={signupSubmit}>
                          <MDBIcon icon="paper-plane" claassName="pr-2" />
                          &nbsp; Sign up{' '}
                        </MDBBtn>
                      </div>
                    </div>
                  )}
                </form>
                <ToastContainer pauseOnFocusLoss={true} />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </MDBCollapse>
    </MDBNavbar>
    // </Router>
  );
};

export default withRouter(Navbar);

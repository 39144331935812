import { GoogleAuthProvider, getAuth } from 'firebase/auth';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  addDoc,
  updateDoc,
  getFirestore,
  query,
  collection,
  getDocs,
} from 'firebase/firestore';

import { toast } from 'react-toastify';

const firebaseConfig = {
  apiKey: 'AIzaSyB_edPbjZpIFPFO0y-gRbYmJel6F8Ln2Jg',
  authDomain: 'pabraces.firebaseapp.com',
  databaseURL: 'https://pabraces-default-rtdb.firebaseio.com',
  projectId: 'pabraces',
  storageBucket: 'pabraces.appspot.com',
  messagingSenderId: '496911408067',
  appId: '1:496911408067:web:7e408644c50d3282bb3b76',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth();

// const db = getFirestore(app, {
//   experimentalForceLongPolling: true,
//   // experimentalAutoDetectLongPolling: true,
//   useFetchStreams: false,
// });

const db = getFirestore(app);

// export const addTodo = async (newTodo) => {
//   try {
//     const docRef = await addDoc(collection(db, 'programs'), {
//       first: 'Ada',
//       last: 'Lovelace',
//       born: 1815,
//     });
//     console.log('Document written with ID: ', docRef.id);
//   } catch (e) {
//     console.error('Error adding document: ', e);
//   }
// };

export const createProgramsList = async (
  userName,
  year,
  title,
  description,
  location,
  date
) => {
  try {
    const docRef = await addDoc(collection(db, 'programs'), {
      userName,
      year,
      title,
      description,
      location,
      date,
    });
    return docRef.id;
  } catch (error) {
    console.log(error);
    toast.error('Error creating program');
  } finally {
  }
};

const updateProgramList = async (state) => {
  console.log('state is ', state);

  try {
    const programDoc = db(collection, 'programs', state.id);
    await updateDoc(programDoc, {
      year: state.year,
      title: state.title,
      description: state.description,
      location: state.location,
      date: state.date,
      createdby: state.createdby,
      created: state.created,
      updated: new Date(),
    });
  } catch (error) {
    toast.error('Error updating record!');
  }
};

const getProgramsList = async () => {
  try {
    const docs = await query(collection(db, 'programs'));
    return docs;
  } catch (error) {
    console.log(error);
  }
};

const getAllPrograms = async () => {
  const querySnapshot = await getDocs(collection(db, 'programs'));
  querySnapshot.forEach((doc) => {
    console.log(`${doc.id} => ${doc.data()}`);
  });
};

// export const getProgram = async (programId) => {
//   try {
//     const doc = await addDoc(collection(db, 'programs')
//       .where('programId', '==', programId));
//     return doc.data();

//   }
//   // const programsColRef = collection(db, 'programsList');
//   // console.log('in col ', programsColRef);
//   // return addDoc(programsColRef, {
//   //   created: serverTimestamp(),
//   //   createdBy: userName,
//   //   year,
//   //   title,
//   //   description,
//   //   location,
//   //   date,
//   // });
// };

export {
  app,
  provider,
  auth,
  firebaseConfig,
  db,
  getAllPrograms,
  getProgramsList,
  updateProgramList,
};

import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
// import Lightbox from 'react-image-lightbox';
import '../extra-styling/gallery.css';
import InstagramFeed from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';

import gallery from '../assets/images/gallery.png';

// const images = [
//   'https://profajayibraces.org/gallery/counsel1.jpg',
//   'https://profajayibraces.org/gallery/counsel2.jpg',
//   'https://profajayibraces.org/gallery/counsel3.jpg',
//   'https://profajayibraces.org/gallery/counsel4.jpg',
//   'https://profajayibraces.org/gallery/counsel5.jpg',
//   'https://profajayibraces.org/gallery/presentation1.jpg',
//   'https://profajayibraces.org/gallery/presentation2.jpg',
//   'https://profajayibraces.org/gallery/presentation3.jpg',
//   'https://profajayibraces.org/gallery/presentation4.jpg',
//   'https://profajayibraces.org/gallery/presentation6.jpg',
//   'https://profajayibraces.org/gallery/students1.jpg',
//   // 'https://profajayibraces.org/gallery/service1.jpg',
//   // "https://profajayibraces.org/gallery/woman.jpg"
// ];

// const smallImages = [
//   'https://profajayibraces.org/gallery/counsel1.jpg',
//   'https://profajayibraces.org/gallery/counsel2.jpg',
//   'https://profajayibraces.org/gallery/counsel3.jpg',
//   'https://profajayibraces.org/gallery/counsel4.jpg',
//   'https://profajayibraces.org/gallery/counsel5.jpg',
//   'https://profajayibraces.org/gallery/presentation1.jpg',
//   'https://profajayibraces.org/gallery/presentation2.jpg',
//   'https://profajayibraces.org/gallery/presentation3.jpg',
//   'https://profajayibraces.org/gallery/presentation4.jpg',
//   'https://profajayibraces.org/gallery/presentation6.jpg',
//   'https://profajayibraces.org/gallery/students1.jpg',
//   // 'https://profajayibraces.org/gallery/service1.jpg',
//   // "https://profajayibraces.org/gallery/woman.jpg"
// ];

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    // const { photoIndex, isOpen } = this.state;
    return (
      <div className="pb-5">
        <MDBContainer className="bg-info pt-1 mb-5" fluid>
          <div className="row my-5 pt-5">
            <div className="col-12 d-flex px-0">
              <img
                className="d-none d-sm-block img-fluid w-25 float-left"
                src={gallery}
                alt="calendar cube"
              />
              <img
                className="d-block d-sm-none img-fluid img-xs-programs float-left mt-3"
                src={gallery}
                alt="calendar cube"
              />
              <div className="align-self-center text-center">
                <h1 className="d-none d-sm-block display-4 pl-4">Gallery</h1>
                <h2 className="d-block d-sm-none display-5-responsive pl-2 mt-3">
                  Gallery
                </h2>
              </div>
            </div>
          </div>
        </MDBContainer>

        <MDBContainer>
          <InstagramFeed
            token="IGQWRQaUZA2UTNIc1pZAUDlWU0NsdlJRZAHlTcEZACTjhmdlBsSFZAGTTFJQUZA1aXJHclBLbjM4NXV3TjVxcGxHTkoySGZARVDUyY08tdXpYclpOOEItNlIxNWxNYkpWSDMtMjdBbjJRYldRY2F4aHBMOGZAUS1pqZA1JXZAlUZD"
            counter="20"
          />
        </MDBContainer>
      </div>
    );
  }
}

export default Gallery;
